import { Components } from "@mui/material";
import { DynamicThemeOptions, themeDefault } from "../foundations";

export const buildMuiTableContainer = (dynamicTheme: DynamicThemeOptions) => {
  const MuiTableContainer: Components["MuiTableContainer"] = {
    styleOverrides: {
      root: {
        borderRadius: "0",
        backgroundColor: "transparent",
        "MuiTableContainer-root": {
          boxShadow: "none",
        },
        ".MuiTableCell-root": {
          color: "#000000",
          fontSize: "0.875rem",
          fontWeight: "500",
          padding: `${themeDefault.spacing(1)} ${themeDefault.spacing(1)}`,
          borderBottomColor: "rgba(144, 148, 166, 0.5)",
          lineHeight: "1.71",
        },
        ".MuiTableCell-head": {
          color: "#000000",
          textTransform: "uppercase",
        },
        ".MuiTableCell-body": {
          ".MuiSvgIcon-root": {},
        },
      },
    },
    defaultProps: {
      color: "default",
    },
  };
  return MuiTableContainer;
};
