import { Components } from '@mui/material';
import { XOutlineIcon } from 'ui/icons';
import { colors, DynamicThemeOptions, shape, themeDefault } from '../foundations';

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    default: true;
  }
}
export const buildMuiChip = (dynamicTheme: DynamicThemeOptions) => {
  const MuiChip: Components['MuiChip'] = {
    styleOverrides: {
      root: {
        borderRadius: shape.borderRadius,
        fontSize: '.875rem',
        lineHeight: '1rem',
        fontWeight: 500,
        height: 'auto',
        cursor: 'pointer',
        '.MuiChip-label': {
          padding: themeDefault.spacing(2),
        },
        '&.Mui-disabled': {
          color: colors.white,
          backgroundColor: colors.neutral200,
          opacity: 1
        },
        '.MuiChip-icon': {
          width: '1.25rem',
          '+ .MuiChip-label': {
            paddingLeft: themeDefault.spacing(1.25),
          }
        }
      },
    },
    variants: [
      {
        props: {
          color: 'default'
        },
        style: {
          backgroundColor: dynamicTheme.mainSection.chipDefault.backgroundColor,
          color: dynamicTheme.mainSection.chipDefault.color,
          '&.rounded': {
            borderRadius: 50,
          },
          '&.MuiChip-root:hover': {
            backgroundColor: dynamicTheme.mainSection.chipDefault.backgroundColorHover,
            color: dynamicTheme.mainSection.chipDefault.colorHover,
          },
        }
      },
      {
        props: {
          color: 'primary'
        },
        style: {
          backgroundColor: dynamicTheme.mainSection.chipPrimary.backgroundColor,
          color: dynamicTheme.mainSection.chipPrimary.color,
          '&.rounded': {
            borderRadius: 50,
          },
          '&.MuiChip-root:hover': {
            backgroundColor: dynamicTheme.mainSection.chipPrimary.backgroundColorHover,
            color: dynamicTheme.mainSection.chipPrimary.colorHover,
          },
        }
      },
      {
        props: {
          color: 'secondary'
        },
        style: {
          backgroundColor: dynamicTheme.mainSection.chipSecondary.backgroundColor,
          color: dynamicTheme.mainSection.chipSecondary.color,
          '&.rounded': {
            borderRadius: 50,
          },
          '&.MuiChip-root:hover': {
            backgroundColor: dynamicTheme.mainSection.chipSecondary.backgroundColorHover,
            color: dynamicTheme.mainSection.chipSecondary.colorHover,
          },
        }
      },
      {
        props: {
          size: 'small'
        },
        style: {
          '&.MuiChip-root': {
            borderRadius: '.5rem',
            height: '2rem',
          },
          '&.MuiChip-label': {
            padding: '0',
          },
        },
      }
    ],
    defaultProps: {
      color: 'default',
      deleteIcon: <XOutlineIcon />
    }
  };
  return MuiChip
};
