import { Components } from '@mui/material';
import { DynamicThemeOptions, themeDefault } from 'ui/foundations';

export const buildMuiBottomNavigation = (dynamicTheme: DynamicThemeOptions) => {
  const MuiBottomNavigation: Components['MuiBottomNavigation'] = {
    styleOverrides: {
      root: {
        backgroundColor: dynamicTheme.mainNavigation.backgroundColor,
        height: '78px'
      },
    }
  };
  return MuiBottomNavigation;
}


export const buildMuiBottomNavigationAction = (dynamicTheme: DynamicThemeOptions) => {
  const MuiBottomNavigation: Components['MuiBottomNavigationAction'] = {
    styleOverrides: {
      root: {
        padding: themeDefault.spacing(2),
        color: dynamicTheme.mainNavigation.link.color,
        minWidth: 40,
        '&.Mui-selected': {
          paddingTop: themeDefault.spacing(2),
          color: dynamicTheme.mainNavigation.link.colorActive,
        },
      },
      label: {
        marginTop: '.5rem',
        fontWeight: '500',
        fontSize: '.75rem',

        '&.Mui-selected': {
          fontWeight: 'bold',
          fontSize: '.75rem',
        }
      }
    }
  };
  return MuiBottomNavigation;
}
