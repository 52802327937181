import { Components } from '@mui/material';
import { colors } from 'ui/foundations';

const MuiAlert: Components['MuiAlert'] = {

  variants: [{
    props: {
      severity: 'success'
    },
    style: {
      backgroundColor: colors.success500,
      color: colors.white,
      alignItems: 'center',
      '.MuiSvgIcon-root': {
        color: colors.white,
      }
    }
  }]

};

export default MuiAlert;
