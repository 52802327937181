import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const CollectMoneyIcon = (props: SvgIconProps) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.1 19.791C1.411 13.665 4.32 7.426 10 4.146 17.653-.272 27.437 2.35 31.854 10 36.272 17.653 33.65 27.437 26 31.854c-5.681 3.28-12.538 2.68-17.5-.98"
        stroke="#fff"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 20.956c0 1.904 1.76 3.468 4 3.468s4-1.564 4-3.468c0-4.42-7.36-2.584-7.36-6.46 0-1.633 1.52-2.925 3.36-2.925 1.84 0 3.36 1.292 3.36 2.924M18.003 9 18 11.57M18.003 26.997v-2.573"
        stroke="#fff"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const SocialNetworkIcon = (props: SvgIconProps) => {
  return (
    <svg
      width="32"
      height="36"
      viewBox="0 0 32 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.048 20.02c-.05-6.26 4.102-11.51 9.823-13.277M16.025 33.953c-2.329 0-4.557-.555-6.481-1.565M28.38 26.483a13.648 13.648 0 0 0 1.57-6.41c0-3.928-1.58-7.412-4.159-9.946"
        stroke="#fff"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M15.972 10.279a4.146 4.146 0 0 0 4.152-4.14A4.146 4.146 0 0 0 15.972 2a4.146 4.146 0 0 0-4.152 4.14 4.146 4.146 0 0 0 4.152 4.139zM6.152 34a4.146 4.146 0 0 0 4.152-4.14 4.146 4.146 0 0 0-4.152-4.139A4.146 4.146 0 0 0 2 29.861 4.146 4.146 0 0 0 6.152 34zM25.847 34A4.146 4.146 0 0 0 30 29.861a4.146 4.146 0 0 0-4.152-4.14 4.146 4.146 0 0 0-4.152 4.14 4.146 4.146 0 0 0 4.152 4.14z"
        stroke="#fff"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export const VideoLinksIcon = (props: SvgIconProps) => {
  return (
    <svg
      width="36"
      height="32"
      viewBox="0 0 36 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M33.998 2H2v28h31.998V8.222M34 8.222h-9.6M6.8 8.222H3.6"
        stroke="#fff"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.906 13.003a.35.35 0 0 1 .469-.042l7.202 4.236a.37.37 0 0 1 .162.144.408.408 0 0 1 .003.425.371.371 0 0 1-.155.143l-6.644 4.022-.78.291a.352.352 0 0 1-.257-.113.403.403 0 0 1-.106-.275v-8.556c0-.103.038-.202.106-.275z"
        stroke="#fff"
        strokeWidth="3"
      />
    </svg>
  );
};

export const GrowYourFollowingIcon = (props: SvgIconProps) => {
  return (
    <svg
      width="36"
      height="23"
      viewBox="0 0 36 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.9999 8.90009C19.8856 8.90009 21.4285 7.34759 21.4285 5.45008C21.4285 3.55258 19.8856 2.00007 17.9999 2.00007C16.1142 2.00007 14.5713 3.55258 14.5713 5.45008C14.5713 7.34759 16.1142 8.90009 17.9999 8.90009Z"
        stroke="white"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.8569 21.1998C24.8569 17.4048 21.7214 14.2998 17.8999 14.2998C14.8624 14.2998 12.2168 16.2836 11.237 18.9573C10.845 20.0786 11.7269 21.1998 12.9027 21.1998H20.4476"
        stroke="white"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.9997 21.1998C33.9997 17.3894 30.865 14.2998 27.1426 14.2998"
        stroke="white"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.4 10.0002C29.76 10.0002 30.8 8.96021 30.8 7.6002C30.8 6.2402 29.76 5.2002 28.4 5.2002C27.04 5.2002 26 6.2402 26 7.6002C26 8.96021 27.12 10.0002 28.4 10.0002Z"
        stroke="white"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.6002 10.0002C8.96021 10.0002 10.0002 8.96021 10.0002 7.6002C10.0002 6.2402 8.96021 5.2002 7.6002 5.2002C6.2402 5.2002 5.2002 6.2402 5.2002 7.6002C5.2002 8.96021 6.3202 10.0002 7.6002 10.0002Z"
        stroke="white"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 21.1998C2 17.3894 5.03674 14.2998 8.85716 14.2998"
        stroke="white"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const MyWebsiteIcon = (props: SvgIconProps) => {
  return (
    <svg
      width="36"
      height="32"
      viewBox="0 0 36 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M34 2H2v28h32V10M23 10H2"
        stroke="#fff"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const MyNewsIcon = (props: SvgIconProps) => {
  return (
    <svg
      width="36"
      height="35"
      viewBox="0 0 36 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M34 2H2v31h32V9M23 9H2M27.602 14.8h-19.2m19.2 6.4h-19.2m8 6.4h-8"
        stroke="#fff"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const MusicChannelIcon = (props: SvgIconProps) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.406 29.084V2H34v23.071M12.774 9.02h13.742"
        stroke="#fff"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.997 24.67c0 2.909-2.355 5.316-5.202 5.316s-5.202-2.407-5.202-5.316c0-2.91 2.355-5.317 5.202-5.317M12.405 28.68c0 2.91-2.356 5.316-5.203 5.316C4.356 33.996 2 31.59 2 28.68s2.356-5.316 5.202-5.316"
        stroke="#fff"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const EndorsementsIcon = (props: SvgIconProps) => {
  return (
    <svg
      width="40"
      height="29"
      viewBox="0 0 40 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M32.334 9.169a4.108 4.108 0 1 0 4.108-7.115 4.108 4.108 0 0 0-4.108 7.115zM13.313 9.254a4.108 4.108 0 1 0 4.108-7.115 4.108 4.108 0 0 0-4.108 7.115zM3.557 26.151a4.108 4.108 0 1 0 4.108-7.115 4.108 4.108 0 0 0-4.108 7.115zM22.75 25.77a4.108 4.108 0 1 0 4.108-7.116 4.108 4.108 0 0 0-4.108 7.115zM17.403 9.26l5.375 9.395M30.283 5.628l-5.215.05M14.937 22.514l-5.215.05"
        stroke="#fff"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const StoreIcon = (props: SvgIconProps) => {
  return (
    <svg
      width="36"
      height="32"
      viewBox="0 0 36 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.908 28.448v-14M25.11 30v-8.75H10.89V23M31.089 14.448v14M2 10.015V7.528L3.333 2h29.332L34 7.528v2.25"
        stroke="#fff"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 8.22h31.998"
        stroke="#fff"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M2 30h10.181M22.364 30H34M17.998 2 18 11M24.544 2.78 25.998 9v3.112M11.454 2.78 9.998 9v3.112"
        stroke="#fff"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 9.78c0 2.577-1.791 4.666-4 4.666-2.21 0-4-2.09-4-4.667M25.998 9.78c0 2.577-1.791 4.666-4 4.666-2.21 0-4-2.09-4-4.667"
        stroke="#fff"
        strokeWidth="3"
      />
      <path
        d="M17.999 9.78c0 2.577-1.791 4.666-4 4.666-2.21 0-4-2.09-4-4.667M33.998 9.78c0 2.577-1.791 4.666-4 4.666-2.21 0-4-2.09-4-4.667"
        stroke="#fff"
        strokeWidth="3"
      />
    </svg>
  );
};

export const UniqueProductIcon = (props: SvgIconProps) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m18 2 16 4.4v23.2l-3.9 1.1-4 1.1-3.5.9L18 34V13l-4.5-1.5M10.563 4.187 18 2M18 34 2 29.6V6.4"
        stroke="#fff"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const EverythingElseIcon = (props: SvgIconProps) => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.982 18.508a7.545 7.545 0 0 0 5.502 3.008 7.535 7.535 0 0 0 5.873-2.192l4.525-4.53a7.553 7.553 0 0 0-.091-10.583 7.539 7.539 0 0 0-10.574-.092l-2.595 2.582"
        stroke="#fff"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.017 15.492a7.545 7.545 0 0 0-5.5-3.008 7.536 7.536 0 0 0-5.874 2.193l-4.526 4.53a7.553 7.553 0 0 0 .092 10.582 7.539 7.539 0 0 0 10.574.092l2.58-2.582"
        stroke="#fff"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const DotIcon = (props: SvgIconProps) => {
  return (
    <svg
      width="80"
      height="16"
      viewBox="0 0 80 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.99953 15.9991C12.4176 15.9991 15.9991 12.4176 15.9991 7.99953C15.9991 3.58151 12.4176 0 7.99953 0C3.58151 0 0 3.58151 0 7.99953C0 12.4176 3.58151 15.9991 7.99953 15.9991Z"
        fill="#31364C"
      />
      <path
        d="M39.9976 15.9991C44.4156 15.9991 47.9971 12.4176 47.9971 7.99953C47.9971 3.58151 44.4156 0 39.9976 0C35.5796 0 31.998 3.58151 31.998 7.99953C31.998 12.4176 35.5796 15.9991 39.9976 15.9991Z"
        fill="#31364C"
      />
      <path
        d="M71.9951 16C76.4134 16 79.9951 12.4183 79.9951 8C79.9951 3.58172 76.4134 0 71.9951 0C67.5768 0 63.9951 3.58172 63.9951 8C63.9951 12.4183 67.5768 16 71.9951 16Z"
        fill="#31364C"
      />
    </svg>
  );
};

export const CheckboxIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="4"
        y="4"
        width="16"
        height="16"
        rx="2"
        stroke="currentcolor"
        fill="#fff"
        strokeWidth="2"
      />
    </SvgIcon>
  );
};

export const CheckboxCheckedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect y="0.893066" width="28" height="27.6127" rx="7" fill="#283676" />
      <path
        d="M11.823 18.4199L21.8325 8.87793L23.3724 10.3459L11.823 21.3559L4.89331 14.75L6.43323 13.282L11.823 18.4199Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export const CheckboxIndeterminateIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="#fff" fillOpacity=".01" d="M0 0h24v24H0z" />
      <rect
        x="4"
        y="4"
        width="16"
        height="16"
        rx="2"
        fill="currentcolor"
        stroke="currentcolor"
        strokeWidth="2"
      />
      <path
        d="M15.75 11h-7.5C7.56 11 7 11.448 7 12s.56 1 1.25 1h7.5c.69 0 1.25-.448 1.25-1s-.56-1-1.25-1z"
        fill="#FAFBFC"
      />
    </SvgIcon>
  );
};

export const OutLineNxivlIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="34"
      height="30"
      viewBox="0 0 34 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.252 28.995h15.465l-7.735-7.806-7.73 7.806zM25 13.092l-4.947 4.995 10.364 10.465c.283.286.667.448 1.068.448 1.165 0 1.891-1.273 1.309-2.287L25 13.093zM22.746 9.16l-4.124-7.202a1.894 1.894 0 0 0-3.296.006L11.218 9.17l5.759 5.816 5.77-5.826zM8.974 13.103 1.2 26.74c-.572 1.003.144 2.255 1.287 2.255.396 0 .775-.157 1.053-.443l10.37-10.47-4.937-4.98z"
        stroke="#fff"
        strokeWidth="2"
      />
    </SvgIcon>
  );
};

export const OutLineInstagramIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="31"
      height="30"
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.043 5.363c-1.014 0-1.739.724-1.739 1.739 0 1.014.725 1.739 1.74 1.739 1.014 0 1.738-.725 1.738-1.74 0-1.014-.724-1.738-1.739-1.738z"
        fill="#fff"
      />
      <path
        d="M8.826 15.072c0-3.505 2.886-6.39 6.391-6.39 3.519 0 6.392 2.753 6.392 6.39 0 3.506-2.886 6.392-6.392 6.392-3.505 0-6.39-2.886-6.39-6.391z"
        stroke="#fff"
        strokeWidth="2"
      />
      <path
        d="M29 21.015V21.044C29.128 25.38 25.552 29 21.015 29H8.985C4.61 29 1 25.39 1 21.015V8.985C1 4.61 4.61 1 8.986 1h12.029C25.39 1 29 4.61 29 8.986v12.029z"
        stroke="#fff"
        strokeWidth="2"
      />
    </SvgIcon>
  );
};

export const OutLineTwitterIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="39"
      height="32"
      viewBox="0 0 39 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.602 31C8.727 31 4.84 29.924 1.39 27.91c3.108.268 6.33-.489 9.024-1.922a1 1 0 0 0-.172-1.838c-2.451-.764-4.159-2.569-4.973-4.878a1 1 0 0 0 .133-1.686 7.366 7.366 0 0 1-2.967-5.893 1 1 0 0 0 .418.091h.178a1 1 0 0 0 .831-1.555c-1.515-2.266-1.71-5.268-.45-7.719 3.706 4.384 8.866 7.12 14.56 7.852a1 1 0 0 0 1.127-.992V8.662C19.098 4.431 22.56 1 26.607 1c1.86 0 3.756.778 5.187 2.047a1 1 0 0 0 .905.222c1.367-.341 2.748-.841 3.997-1.51-.283.66-.57 1.186-.961 1.706a1 1 0 0 0 1.245 1.496c.065-.032.139-.054.328-.101l.022-.006a6.48 6.48 0 0 0 .18-.047c-.949 1.214-2.006 2.361-3.17 3.232a1 1 0 0 0-.401.8V9.385C34.108 21.197 24.635 30.827 12.772 31H12.603z"
        stroke="#fff"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export const OutLineTikTokIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="31"
      height="34"
      viewBox="0 0 31 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M29 13.94h-.713c-2.854 0-5.707-1.399-7.313-3.847v13.115c0 5.42-4.458 9.792-9.987 9.792C5.46 33 1 28.628 1 23.208c0-5.421 4.459-9.793 9.987-9.793h.535v4.722h-.535c-2.853 0-4.993 2.273-4.993 4.896 0 2.623 2.318 4.896 4.993 4.896 2.854 0 5.35-2.098 5.35-4.896L16.16 1h4.637c.357 4.197 3.745 7.344 8.026 7.694L29 13.94z"
        stroke="#fff"
        strokeWidth="2"
      />
    </SvgIcon>
  );
};

export const OutLineFacebookIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21 20L22 15.9H17.968V13.2C17.968 12 18.544 10.9 20.272 10.9H22V7.3C22 7.3 20.368 7 18.832 7C15.664 7 13.552 9 13.552 12.7V15.9H10V20H13.552V30C13.552 30 13 31 16 31C19 31 17.968 30 17.968 30V20H21Z"
        stroke="white"
        strokeWidth="2"
      />
      <circle cx="16" cy="16" r="15" stroke="white" strokeWidth="2" />
    </SvgIcon>
  );
};

export const OutLineYoutubeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="36"
      height="25"
      viewBox="0 0 36 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.5462 6.70725L15.5329 6.70014L15.5194 6.69344C15.2225 6.54618 14.9029 6.50733 14.6597 6.50231C14.3998 6.49693 14.1291 6.52852 13.878 6.59079C13.6364 6.65073 13.3516 6.7542 13.1022 6.93202C12.8652 7.10102 12.5 7.46163 12.5 8.03571V17.1875C12.5 17.7616 12.8652 18.1222 13.1022 18.2912C13.3516 18.469 13.6364 18.5725 13.878 18.6324C14.1291 18.6947 14.3998 18.7263 14.6597 18.7209C14.9029 18.7159 15.2225 18.677 15.5194 18.5298L24.5194 14.0655C24.8156 13.9185 25.1329 13.6411 25.2361 13.1907C25.3282 12.7885 25.1989 12.4523 25.1086 12.2731C24.9474 11.9533 24.6728 11.6834 24.5719 11.5843C24.5653 11.5777 24.5594 11.5719 24.5543 11.5669L24.4503 11.4637L24.3212 11.3947L15.5462 6.70725ZM28.125 1C31.8552 1 35 4.13121 35 7.8125V17.1875C35 20.8688 31.8552 24 28.125 24H7.875C4.14476 24 1 20.8688 1 17.1875V7.8125C1 4.13121 4.14476 1 7.875 1H28.125Z"
        stroke="white"
        strokeWidth="2"
      />
    </SvgIcon>
  );
};
