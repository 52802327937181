import { ThemeOptions } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Theme {
    colors: {
      primaryColor: string;
      secondaryColor: string;
      black: string;
      white: string;
      blue: string;
      blackblur: string;
      blueDark: string;
      blueLight: string;
      blackPrimary: string;
      brown: string;
      deltaYellow: string;
      marineBlue: string;
      neutral25: string;
      neutral050: string;
      neutral075: string;
      neutral100: string;
      neutral200: string;
      neutral300: string;
      neutral400: string;
      neutral450: string;
      neutral500: string;
      neutral550: string;
      neutral600: string;
      neutral700: string;
      neutral800: string;
      neutral900: string;
      neutral925: string;
      error50: string;
      error500: string;
      errorButton: string;
      success50: string;
      success500: string;
      successButton: string;
      warning50: string;
      warning500: string;
      warningButton: string;
      info50: string;
      info500: string;
      infoButton: string;
      goldGradient: string;
      textColor: string;
      borderColor: string;
      subTextColor: string;
    };
  }
  interface ThemeOptions {
    colors: {
      primaryColor: string;
      secondaryColor: string;
      black: string;
      white: string;
      blue: string;
      blackblur: string;
      blueDark: string;
      blackPrimary: string;
      brown: string;
      blueLight: string;
      deltaYellow: string;
      marineBlue: string;
      neutral025: string;
      neutral050: string;
      neutral075: string;
      neutral100: string;
      neutral200: string;
      neutral300: string;
      neutral400: string;
      neutral450: string;
      neutral500: string;
      neutral550: string;
      neutral600: string;
      neutral700: string;
      neutral800: string;
      neutral900: string;
      neutral925: string;
      error050: string;
      error500: string;
      errorButton: string;
      success050: string;
      success500: string;
      successButton: string;
      warning050: string;
      warning500: string;
      warningButton: string;
      info050: string;
      info500: string;
      infoButton: string;
      goldGradient: string;
      textColor: string;
      borderColor: string;
      subTextColor: string;
    };
  }
}

export const colors: ThemeOptions["colors"] = {
  primaryColor: "#283676",
  secondaryColor: "#E01E26",
  black: "#000",
  white: "#fff",
  blue: "#345BFF",
  blackblur: "#090B12",
  blueDark: "#141826",
  blackPrimary: "#252A3C",
  blueLight: "#1f295d",
  brown: "#d0ae5c",
  deltaYellow: "#ffcb05",
  marineBlue: "#032e59",
  neutral025: "#F5F6FA",
  neutral050: "#E9EAF2",
  neutral075: "#DADCE5",
  neutral100: "#CACDD9",
  neutral200: "#B0B3BF",
  neutral300: "#A1A4B2",
  neutral400: "#9094A6",
  neutral450: "#828699",
  neutral500: "#666B80",
  neutral550: "#565C73",
  neutral600: "#474D66",
  neutral700: "#3C4159",
  neutral800: "#31364D",
  neutral900: "#262B40",
  neutral925: "#1F2333",
  error050: "#FFEDF6",
  error500: "#CA126B",
  errorButton: "#EA2182",
  success050: "#EAFDF7",
  success500: "#25C690",
  successButton: "#2FDCA2",
  warning050: "#FFF9E3",
  warning500: "#FFC400",
  warningButton: "#FFD751",
  info050: "#EDF0FF",
  info500: "#345BFF",
  infoButton: "#3479FF",
  goldGradient:
    "linear-gradient(96deg, #fceeca 11%, #e7c982 23%, #c29d45 44%, #ceaf4a 69%, #e8c46a 81%)",
  textColor: "#555555",
  borderColor: "#dddddd",
  subTextColor: "#777777",
};
