import { Components } from '@mui/material';
import { colors } from 'ui/foundations';

const MuiFormControlLabel: Components['MuiFormControlLabel'] = {
  styleOverrides: {
    root: {
      color: colors.white,
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: '1rem',
      userSelect: 'none',
    },
    label: {
      fontSize: '1rem',
      lineHeight: '1rem',
    }
  },
  variants: [
  ],
  defaultProps: {

  }
};

export default MuiFormControlLabel;
