import { createContext, useContext } from "react"
import {
  useMediaQuery,
  useTheme,
} from "@mui/material";



interface AppContextType {
  isTablet: boolean
}

interface AppWrapperType {
  children: JSX.Element
}

const AppContext = createContext<AppContextType>({} as AppContextType)


export const AppWrapper = ({ children }: AppWrapperType) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <AppContext.Provider
      value={{
        isTablet
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export const useAppContext = () => {
  return useContext(AppContext)
}