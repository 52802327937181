import { Components } from '@mui/material';
import { DynamicThemeOptions } from '../foundations/dynamicTheme';

export const buildMuiSvgIcon = (dynamicTheme: DynamicThemeOptions) => {
  const MuiSvgIcon: Components['MuiSvgIcon'] = {
    styleOverrides: {
      root: {
        fill: 'none',
        stroke: 'none',
        '&.stroke-color': {
          '& path': {
            stroke: dynamicTheme.mainSection.text.color
          },
          '& circle': {
            stroke: dynamicTheme.mainSection.text.color
          }
        },
        '&.fill-color': {
          '& path': {
            fill: dynamicTheme.mainSection.text.color
          },
        }
      }
    }
  };
  return MuiSvgIcon;
}
