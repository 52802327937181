import { TypographyOptions } from "@mui/material/styles/createTypography";
import { DynamicThemeOptions } from "./dynamicTheme";
import { colors } from "./colors";

export const buildTypography = (dynamicTheme: DynamicThemeOptions) => {
  const typography: TypographyOptions = {
    fontSize: 16,
    fontFamily: "Montserrat, sans-serif",

    h1: {
      fontSize: "2.625rem",
      lineHeight: "3.25rem",
      fontWeight: "bold",
      color: colors.black,
    },
    h2: {
      fontSize: "2rem",
      lineHeight: "2.5rem",
      fontWeight: "bold",
      color: colors.black,
    },
    h3: {
      fontSize: "1.25rem",
      lineHeight: "1.75rem",
      fontWeight: "bold",
      color: colors.black,
    },
    h4: {
      fontSize: "1.125rem",
      fontWeight: "bold",
      color: colors.black,
    },
    h5: {
      fontSize: "1rem",
      fontWeight: "500",
      color: colors.black,
    },
    h6: {
      fontSize: ".875rem",
      fontWeight: "bold",
      color: colors.black,
    },
    body1: {
      color: colors.black,
    },
    body2: {
      color: colors.black,
    },
  };
  return typography;
};
