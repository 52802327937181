import { Components } from '@mui/material';

const MuiInput: Components['MuiInput'] = {

  defaultProps: {
    disableUnderline: true
  }
};

export default MuiInput;
