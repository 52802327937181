import { Components } from '@mui/material';
import { DynamicThemeOptions } from '../foundations';


export const buildMuiMobileStepper = (dynamicTheme: DynamicThemeOptions) => {

  const MuiMobileStepper: Components['MuiMobileStepper'] = {
    styleOverrides: {
      root: {
        backgroundColor: dynamicTheme.mainNavigation.backgroundColor,
        justifyContent: 'center',
        marginTop: '1rem',
        padding: 0
      },
      dot: {
        '&:not(.MuiMobileStepper-dotActive)': {
          backgroundColor: '#fff',
          opacity: 0.2
        }
      }
    },
    variants: [],
    defaultProps: {
    },
  };
  return MuiMobileStepper;
};

