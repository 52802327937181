import "../styles/globals.css";
import type { AppProps, LayoutOptions } from "next/app";
import { MuiThemeProvider } from "../providers";
import { AppWrapper } from "providers/DeviceProvider";

declare global {
  interface Window { googleTranslateElementInit: any; google: any }
}
export default function App({ Component, pageProps }: AppProps) {
  const getLayout = Component.getLayout ?? ((page) => page);
  const { layoutOptions, ...restPageProps } = pageProps;
  const options: LayoutOptions = {
    ...Component.layoutOptions,
    ...layoutOptions,
  };

  return (
    <AppWrapper>
      <MuiThemeProvider dynamicTheme={undefined}>
        {getLayout(<Component {...restPageProps} />, options)}
      </MuiThemeProvider>
    </AppWrapper>
  );
}
