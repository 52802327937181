import { Components } from '@mui/material';
import { colors, DynamicThemeOptions, themeDefault } from 'ui/foundations';

export const buildMuiDrawer = (dynamicTheme: DynamicThemeOptions) => {
  const MuiDrawer: Components['MuiDrawer'] = {
    styleOverrides: {
      root: {
        '.MuiDrawer-paper': {
          width: '23.375rem',
          display: 'block',
          overflowX: 'hidden',
          '&.MuiDrawer-paperAnchorBottom': {
            width: '100%',
          },
          [themeDefault.breakpoints.down('sm')]: {
            width: '100%',
          }
        },
        '.drawer-primary, .MuiDrawer-paper': {
          backgroundColor: dynamicTheme.sidebarNavigation.backgroundColor,
        },
        '.drawer-secondary': {
          backgroundColor: colors.white,
          '.MuiTypography-root': {
            color: colors.black,
          }
        }

      }
    },
  };

  return MuiDrawer;
}
export default buildMuiDrawer;
