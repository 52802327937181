import { Components } from '@mui/material';
import { colors } from 'ui/foundations';
import { CheckboxIcon, CheckboxCheckedIcon, CheckboxIndeterminateIcon } from '../icons';

const MuiCheckbox: Components['MuiCheckbox'] = {
  styleOverrides: {
    root: {
      color: colors.blue,
      '.checkbox-uncheck': {
        'rect': {
          fill: 'transparent'
        },

      },
      '&.error-checkbox': {
        '.MuiSvgIcon-root': {
          color: colors.error500,
        }
      }
    },
  },
  variants: [
  ],
  defaultProps: {
    icon: <CheckboxIcon fontSize="small" className="checkbox-uncheck" />,
    checkedIcon: <CheckboxCheckedIcon fontSize="small" />,
    indeterminateIcon: <CheckboxIndeterminateIcon fontSize="small" />,
  }
};

export default MuiCheckbox;
