import { Components } from '@mui/material';
import { colors } from 'ui/foundations';
import { themeDefault } from '../foundations';

const MuiCard: Components['MuiCard'] = {
  styleOverrides: {
    root: {
      backgroundColor: colors.blueDark,
      color: colors.white,
      marginBottom: `${themeDefault.spacing(2)}`,
      padding: `${themeDefault.spacing(2)}`,
    },
  },
  defaultProps: {

  }
};

export default MuiCard;