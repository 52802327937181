import { Components } from '@mui/material';
import { DynamicThemeOptions } from 'ui/foundations';

export const buildMuiFormControl = (dynamicTheme: DynamicThemeOptions) => {

  const MuiFormControl: Components['MuiFormControl'] = {
    styleOverrides: {
      root: {
        '&.checkbox-control-outlined': {
          width: '100%',
          '.MuiFormLabel-root': {
            padding: '1rem 0'
          },
          '.MuiFormGroup-root': {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            width: '100%',
            overflow: 'auto',

          },
          '.MuiFormControlLabel-root': {
            backgroundColor: dynamicTheme.mainSection.backgroundColor,
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyContent: 'space-between',
            marginRight: '1.5rem',
            marginLeft: 0,
            marginBottom: '1rem',
            padding: '0 1rem ',
            borderRadius: '8px'
          }
        }
      }
    },
  };

  return MuiFormControl
}

export default buildMuiFormControl;
