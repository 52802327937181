import { Components } from '@mui/material';
import { colors } from 'ui/foundations';
import { EmptyCircleOutlineIcon, SuccessCircleOutlineIcon } from '../icons';

const MuiRadio: Components['MuiRadio'] = {
  styleOverrides: {
    root: {
      color: colors.blue
    },
  },
  variants: [
  ],
  defaultProps: {
    icon: <EmptyCircleOutlineIcon fontSize="small" />,
    checkedIcon: <SuccessCircleOutlineIcon fontSize="small" />,
  }
};

export default MuiRadio;
