import { Components } from '@mui/material';
import { DynamicThemeOptions } from '../foundations';

export const buildMuiSelect = (dynamicTheme: DynamicThemeOptions) => {
  const MuiSelect: Components['MuiSelect'] = {
    styleOverrides: {
      select: {
        '&.MuiSelect-select': {
          color: dynamicTheme.mainSection.text.color,
          fontSize: '.875rem',
          fontWeight: '700',
        },
      }
    },
  };
  return MuiSelect
};
