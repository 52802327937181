import { Components } from "@mui/material";
import { colors } from "ui/foundations";

//TextFieldPropsSizeOverrides
declare module "@mui/material/TextField" {
  interface TextFieldPropsSizeOverrides {
    large: true;
    medium: true;
    small: true;
  }
}

const MuiTextField: Components["MuiTextField"] = {
  styleOverrides: {
    root: {},
  },
  variants: [
    {
      props: {
        variant: "standard",
      },
      style: {
        ".MuiInputLabel-root": {
          color: colors.black,
          fontWeight: 500,
          fontSize: "1rem",
          lineHeight: "1rem",
          transform: "none",
          "&.Mui-focused": {
            color: colors.black,
          },
          "&.Mui-error": {
            color: colors.black,
          },
        },
        "&.Mui-error": {
          color: colors.black,
        },
        ".MuiInput-root": {
          borderRadius: 8,
          backgroundColor: colors.neutral050,
          border: `1px solid ${colors.neutral100}`,
        },
        ".MuiInputBase-root": {
          marginTop: "1.5rem",
        },
        ".MuiInputBase-adornedEnd": {
          paddingRight: 12,
        },
        ".MuiInputBase-input": {
          fontSize: "16px",
          lineHeight: "1.125rem",
          padding: "11px 12px",
          color: colors.blueDark,
          borderRadius: `8px !important`,
          backgroundColor: `${colors.white}!important`,
          "&::placeholder": {
            backgroundColor: `${colors.white}!important`,
            color: colors.neutral600,
            fontWeight: 400,
          },
          "&:focus": {
            caretColor: colors.blue,
          },
          "&::-webkit-credentials-auto-fill-button, &::-webkit-contacts-auto-fill-button":
            {
              display: "none !important",
              visibility: "hidden",
              pointerEvents: "none",
              position: "absolute",
              right: 0,
            },
          "&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active":
            {
              backgroundColor: `${colors.neutral050}!important`,
              WebkitBoxShadow: `0 0 0 62.5rem ${colors.neutral050} inset !important`,
            },
        },
        ".MuiInputBase-multiline": {
          padding: 0,

          "&.MuiInputBase-adornedEnd": {
            paddingRight: 12,
          },
        },
        ".MuiInputBase-inputAdornedEnd": {
          paddingRight: 0,
        },
        ".MuiSvgIcon-root": {
          fontSize: "1.7rem",
          color: `${colors.neutral100} !important`,
          fill: `${colors.neutral100} !important`,
          right: "10px",
        },
        ".Mui-error": {
          ".MuiSvgIcon-root": {
            color: colors.error500,
          },
        },
      },
    },
    {
      props: {
        size: "large",
      },
      style: {
        ".MuiInputBase-input": {
          paddingTop: ".75rem",
          paddingBottom: ".75rem",
          height: "5rem !important",
        },
        ".MuiSvgIcon-root": {
          fontSize: "1.5rem",
        },
      },
    },
  ],
  defaultProps: {
    variant: "standard",
    margin: "normal",
    fullWidth: true,
    InputLabelProps: {
      shrink: true,
    },
  },
};

export default MuiTextField;
