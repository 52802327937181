import { Components } from '@mui/material';
import { DynamicThemeOptions, themeDefault } from '../foundations';
import { colors } from 'ui/foundations';


export const buildMuiTabs = (dynamicTheme: DynamicThemeOptions) => {
  const MuiTabs: Components['MuiTabs'] = {
    styleOverrides: {
      root: {
        '.MuiButtonBase-root.MuiTab-root': {
          color: dynamicTheme.mainSection.text1.color,
          fontSize: '0.875rem',
          fontWeight: 600,
          textTransform: 'capitalize',
          borderBottom: `0.063rem solid ${colors.neutral450}`,
        },
        '.MuiButtonBase-root.MuiTab-root.Mui-selected': {
          color: dynamicTheme.mainSection.text.color,
        },
        '.MuiTabs-indicator': {
          backgroundColor: colors.blue,
          height: '0.063rem',
        }
      },
    },
    variants: [
      {
        props: {
          variant: 'scrollable',
        },
        style: {
          '.MuiButtonBase-root.MuiTab-root': {
            border: 'none',
            borderRadius: '0.75rem',
            marginRight: themeDefault.spacing(2),
            minWidth: '9.375rem',
            color: colors.white,
            fontWeight: 600,
            padding: 0,
            '&:last-child': {
              marginRight: 0,
            },
            '&.Mui-selected': {
              backgroundColor: colors.blueDark,
              borderBottomColor: colors.neutral700
            },
            '> div': {
              width: '100%',
              border: `0.063rem solid ${colors.neutral700}`,
              borderRadius: '0.75rem',
              padding: themeDefault.spacing(2),
            },
          },
          '.MuiTabs-indicator': {
            display: 'none',
          },
          '.MuiTabScrollButton-root': {
            display: 'flex',
            opacity: '1',
            width: 'auto',
            position: 'relative',
            '&.Mui-disabled': {
              display: 'none',
            },
            '&:before': {
              position: 'absolute',
              content: '""',
              width: '100%',
              height: '100%',
              right: '-100%',
              top: '0',
              backgroundImage: 'linear-gradient(to left, #000 40%, rgba(0, 0, 0, 0) 97%)',
              zIndex: '9',
              transform: 'rotate(-180deg)',
            },
            '.MuiSvgIcon-root': {
              backgroundColor: colors.white,
              fill: colors.black,
              borderRadius: '50%;',
              marginLeft: themeDefault.spacing(0.125),
            },
          },
          '.MuiTabs-scrollableX + .MuiTabScrollButton-root': {
            '&:before': {
              right: '100%',
              transform: 'rotate(0deg)',
            }
          }
        }
      },
      {
        props: {
          variant: 'standard',
        },
        style: {
          '&.MuiTabs-root': {
            borderBottom: `0.063rem solid ${colors.neutral700}`
          },
        }
      }
    ],
    defaultProps: {
      color: 'default',
    }
  };
  return MuiTabs
};
