import { Components } from '@mui/material';
import { themeDefault, DynamicThemeOptions } from '../foundations';

export const buildMuiToggleButton = (dynamicTheme: DynamicThemeOptions) => {
  const MuiToggleButton: Components['MuiToggleButton'] = {
    styleOverrides: {
      root: {
        backgroundColor: dynamicTheme.mainSection.toggleButton.backgroundColor,
        color: dynamicTheme.mainSection.toggleButton.color,
        padding: `${themeDefault.spacing(0.75)} ${themeDefault.spacing(1)}`,
        '&.Mui-selected,&.Mui-selected:hover,:hover': {
          backgroundColor: dynamicTheme.mainSection.toggleButton.backgroundColorHover,
        }
      },
    },
    variants: [
      {
        props: {
          size: 'small',
        },
        style: {
          minWidth: 50,
          minHeight: 40,
          '.MuiSvgIcon-root': {
            fontSize: 20,
          }
        }
      }
    ]
  };
  return MuiToggleButton
};