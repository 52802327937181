import { Components } from '@mui/material';
import { colors } from 'ui/foundations';

const MuiSwitch: Components['MuiSwitch'] = {
  styleOverrides: {
    root: {
      width: 42,
      height: 26,
      padding: 0,
      marginRight: '1rem',
    },
    switchBase: {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: colors.white,
        '& + .MuiSwitch-track': {
          backgroundColor: colors.blue,
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: colors.blue,
        border: `6px solid ${colors.white}`,
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: colors.neutral200,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.7,
      },
    },
    thumb: {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    track: {
      borderRadius: 26 / 2,
      backgroundColor: colors.neutral200,
      opacity: 1,
      transition: 'background-color 0.5s ease'
    },
  },
  variants: [
  ],
  defaultProps: {
  }
};

export default MuiSwitch;
