import { Components } from '@mui/material';
import { DynamicThemeOptions } from '../foundations/dynamicTheme';

export const buildMuiSkeleton = (dynamicTheme: DynamicThemeOptions) => {
    const MuiSkeleton: Components['MuiSkeleton'] = {
        styleOverrides: {
            root: {
                backgroundColor: dynamicTheme.mainSection.alertBackgroundColor,
                height: '0.875rem',
                '&:after': {
                    background: 'linear-gradient(90deg, #00000000, rgba(0, 0, 0, 0.2), transparent)'
                }
            },
            text: {
                borderRadius: '0',
            },
            rectangular: {
                borderRadius: '0.5rem'
            }
        }
    };
    return MuiSkeleton;
}