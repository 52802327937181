import { buildMuiButton } from './MuiButton';
import { buildMuiChip } from './MuiChip';
import { buildMuiCssBaseline } from './MuiCssBaseline';
import MuiInput from './MuiInput';
import { buildMuiSvgIcon } from './MuiSvgIcon';
import MuiTextField from './MuiTextFIeld';
import MuiAutocomplete from './MuiAutocomplete';
import MuiCheckbox from './MuiCheckbox';
import MuiFormControlLabel from './MuiFormControlLabel';
import MuiFormLabel from './MuiFormLabel';
import MuiRadio from './MuiRadio';
import MuiFormHelperText from './MuiFormHelperText';
import MuiSwitch from './MuiSwitch';
import MuiCard from './MuiCard';
import MuiDivider from './MuiDivider';
import { DynamicThemeOptions } from 'ui/foundations';
import { buildMuiBottomNavigation, buildMuiBottomNavigationAction } from './MuiBottomNavigation';
import { buildMuiMenu } from './MuiMenu';
import MuiTooltip from './MuiTooltip';
import MuiDialog from './MuiDialog';
import { buildMuiSelect } from './MuiSelect';
import { buildMuiMobileStepper } from './MuiMobileStepper';
import buildMuiFormControl from './MuiFormControl';
import { buildMuiTableContainer } from './MuiTableContainer';
import { buildMuiTabs } from './MuiTabs';
import buildMuiDrawer from './MuiDrawer';
import { buildMuiSkeleton } from './MuiSkeleton';
import MuiAlert from './MuiAlert';
import MuiSnackbar from './MuiSnackbar';
import { buildMuiToggleButton } from './MuiToggleButton';
import MuiSlider from './MuiSlider';

export const buildComponents = (dynamicTheme: DynamicThemeOptions) => {

  return {
    MuiButton: buildMuiButton(dynamicTheme),
    MuiChip: buildMuiChip(dynamicTheme),
    MuiCssBaseline: buildMuiCssBaseline(dynamicTheme),
    MuiTextField,
    MuiInput,
    MuiSvgIcon: buildMuiSvgIcon(dynamicTheme),
    MuiAutocomplete,
    MuiCheckbox,
    MuiRadio,
    MuiFormControlLabel,
    MuiFormControl: buildMuiFormControl(dynamicTheme),
    MuiFormLabel,
    MuiFormHelperText,
    MuiSwitch,
    MuiCard,
    MuiDivider,
    MuiBottomNavigation: buildMuiBottomNavigation(dynamicTheme),
    MuiBottomNavigationAction: buildMuiBottomNavigationAction(dynamicTheme),
    MuiMenu: buildMuiMenu(dynamicTheme),
    MuiTooltip,
    MuiDialog,
    MuiSelect: buildMuiSelect(dynamicTheme),
    MuiMobileStepper: buildMuiMobileStepper(dynamicTheme),
    MuiTableContainer: buildMuiTableContainer(dynamicTheme),
    MuiTabs: buildMuiTabs(dynamicTheme),
    MuiDrawer: buildMuiDrawer(dynamicTheme),
    MuiSkeleton: buildMuiSkeleton(dynamicTheme),
    MuiAlert,
    MuiSnackbar,
    MuiToggleButton: buildMuiToggleButton(dynamicTheme),
    MuiSlider
  }
};
