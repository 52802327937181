import { Components } from '@mui/material';
import { colors } from 'ui/foundations';
import { XOutlineIcon, ChevronDownOutlineIcon } from '../icons';

const MuiAutocomplete: Components['MuiAutocomplete'] = {

  styleOverrides: {
    root: {
      '.MuiInput-root': {
        '.MuiInput-input': {
          padding: '11px 12px',
        }
      },
    },
    inputRoot: {
      backgroundColor: colors.white,
    },
    popper: {
      marginTop: '.25rem !important',
      '.MuiCheckbox-root': {
        '&.MuiCheckbox-colorPrimary': {
          color: colors.blue
        }
      },
      '.nx-user-info': {
        display: 'flex',
        flexDirection: 'column',
        '.nx-avatar-name': {
          color: colors.black,
          order: 0,
          fontSize: '0.75rem',
          fontWeight: 500,
        },
        '.nx-avatar-id': {
          color: colors.neutral500,
          order: 1,
          fontSize: '0.75rem',
          fontWeight: 500,

        }
      }
    },
    paper: {
      fontWeight: 500,
      fontSize: '.75rem',
      lineHeight: '1.125rem',
      color: colors.black
    },
    option: {

      '&.Mui-focusVisible': {
        backgroundColor: colors.neutral025
      },
      '&[aria-selected="true"]': {
        backgroundColor: colors.neutral025,
        '&.Mui-focused': {
          backgroundColor: colors.neutral025,
        }
      },
      '&.autocomplete-list-item': {
        justifyContent: 'space-between',
      },
      '.option-detail': {
        color: colors.neutral550
      }
    },
    tag: {
      backgroundColor: colors.neutral050,
      borderRadius: 8,
      color: colors.black,
      fontSize: '.75rem',
      lineHeight: '1.125rem',
      fontWeight: 500,
      '&:first-of-type': {
        marginLeft: 12,
      },
      '&.MuiChip-root': {
        '&:hover': {
          backgroundColor: colors.neutral200,
        }
      },
      '.MuiChip-label': {
        padding: '3px 8px',
      },
      '.MuiSvgIcon-root': {
        fill: 'currentColor',
        fontSize: '.75rem',
      }
    },
    endAdornment: {
      right: '11px'
    },
    clearIndicator: {

    },

  },
  defaultProps: {
    disablePortal: true,
    clearIcon: <XOutlineIcon />,
    popupIcon: <ChevronDownOutlineIcon />,
  }
};

export default MuiAutocomplete;
