import { Components } from '@mui/material';
import { colors, DynamicThemeOptions } from 'ui/foundations';
import { themeDefault } from '../foundations';

export const buildMuiCssBaseline = (dynamicTheme: DynamicThemeOptions) => {

  const MuiCssBaseline: Components['MuiCssBaseline'] = {
    styleOverrides: {
      body: {
        background: dynamicTheme.mainSection.backgroundColor,
      },
      a: {
        cursor: 'pointer',
        textDecoration: 'none!important',
        color: `${dynamicTheme.mainSection.link.color} !important`,
        '&:hover': {
          color: `${dynamicTheme.mainSection.link.colorHover} !important`,
        }
      },
      '.nx-flex-box': {
        display: 'flex',
      },
      '.nx-d-block': {
        display: 'block!important',
      },
      '.overlay': {
        position: 'relative',
      },
      '.overlay:before': {
        position: 'absolute',
        content: '""',
        width: '100%',
        height: '100%',
        left: '0',
        top: '0',
        borderRadius: '.75rem',
        backgroundImage: `linear-gradient(179deg, rgba(20, 24, 38, 0) -13%, ${colors.blueDark} 97%);`
      },
      '.nx-advertise-box': {
        marginBottom: `${themeDefault.spacing(4)}!important`,
      },
      '.nx-advertise-img': {
        borderRadius: '.75rem',
      },
      'button.nx-outline-primary': {
        borderColor: `${colors.blue}!important`,
        color: `${colors.blue}!important`,
        'svg path': {
          fill: `${colors.blue}!important`,
        }
      },
      '.nx-down-arrow': {
        textAlign: 'center',
        cursor: 'pointer',
        height: '2rem',
        '.nx-down-arrow-icon': {
          color: colors.neutral500,
          margin: `${themeDefault.spacing(1)} 0`,
          'path': {
            stroke: `${colors.neutral500}!important`,
          },
        },
      },
      '.text-blue': {
        color: colors.blue
      },
      '.nx-scrollbar': {
        '::-webkit-scrollbar': {
          width: '0.4em',
          backgroundColor: dynamicTheme.sidebarNavigation.scrollbarBackground,
          borderRadius: '.313rem'
        },
        '::-webkit-scrollbar-thumb': {
          backgroundColor: dynamicTheme.sidebarNavigation.scrollbarThumbBackground,
          borderRadius: '.313rem'

        },
      },
      '.svg-stroke-color': {
        '& path': {
          stroke: dynamicTheme.mainSection.text.color
        }
      },
      // select dropdown menu start;
      '.nx-dropdown-box': {
        '.MuiPaper-root': {
          backgroundColor: dynamicTheme.mainSection.backgroundColor,
          boxShadow: '0 .5rem 1rem 0 rgb(0 0 0 / 90%)',
        },
        '.MuiList-root': {
          backgroundColor: dynamicTheme.mainSection.backgroundColor,
          minWidth: 'inherit',
        },
        '.MuiMenuItem-root': {
          padding: `${themeDefault.spacing(1)} ${themeDefault.spacing(2.5)}!important`,
          fontSize: '.875rem',
          '&.Mui-selected, &:hover, &.Mui-selected:hover': {
            backgroundColor: `${dynamicTheme.mainSection.listing.backgroundColorActive}!important`,
          },
        },
      },
      '.nx-datepicker-dialog-props': {
        '.MuiCalendarPicker-root': {
          '.PrivatePickersFadeTransitionGroup-root': {
            color: colors.black,

            '&~.MuiButtonBase-root .MuiSvgIcon-root': {
              fill: colors.black
            }
          },
        }
      },
      '.nx-dialog-content': {
        textAlign: 'center',
        padding: `0 ${themeDefault.spacing(2)} !important`,
        margin: `${themeDefault.spacing(4)} 0 !important`,
      },
      '.custom-scrollbar': {
        '.thumb-horizontal,.thumb-vertical': {
          background: colors.neutral550,
          cursor: 'pointer',
          borderRadius: 'inherit'
        },
        '.nx-scrollbar-track': {
          right: '0.313rem',
          top: '0.125rem',
          bottom: '0.125rem',
          borderRadius: '0.188rem',
        },
        '.nx-horizontal-scrollbar-track': {
          right: '0.125rem',
          bottom: '0',
          left: '0.125rem',
          borderRadius: '0.188rem',
        },
      },
      // select dropdown menu end;
      '.tab-button': {
        width: '50%!important',
        maxWidth: '50%!important',
      },
      // multiple notification;
      '.SnackbarContainer-root': {
        display: 'block!important',
        margin: '0 auto!important',
        width: '62.5rem!important',
        '& > div ': {
          position: 'absolute',
          top: 40,
          width: '100%',
        },
        [themeDefault.breakpoints.down('md')]: {
          width: '100%!important',
        }
      },
      //Load more button
      '.load-more-btn': {
        width: 212,
      },
      //status
      '.status-completed': {
        color: `${colors.success500}!important`,
      },
      '.status-failed': {
        color: `${colors.error500}!important`,
      },
      '.status-processing': {
        color: `${colors.warning500}!important`,
      },
      '.status-default': {
        color: `${colors.info500}!important`,
      },
      '.cursor-pointer': {
        cursor: 'pointer',
      },

      // royalty dropdown menu start;
      '.royalty-dropdown-box': {
        '.MuiPaper-root': {
          backgroundColor: dynamicTheme.mainSection.backgroundColor,
          boxShadow: '0 .5rem 1rem 0 rgb(0 0 0 / 90%)',
        },
        '.MuiList-root': {
          backgroundColor: dynamicTheme.mainSection.backgroundColor,
          minWidth: 'inherit',
        },
        '.MuiMenuItem-root': {
          padding: `${themeDefault.spacing(1)} ${themeDefault.spacing(2.5)}!important`,
          fontSize: '0.75rem',
          '&.Mui-selected, &:hover, &.Mui-selected:hover': {
            backgroundColor: `${dynamicTheme.mainSection.listing.backgroundColorActive}!important`,
          },
        },
      },
    }
  };
  return MuiCssBaseline;
};
// export default MuiCssBaseline;
