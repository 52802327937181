import { Components } from "@mui/material";
import { DynamicThemeOptions, themeDefault } from "ui/foundations";

declare module "@mui/material/Menu" {
  interface MenuPropsVariantOverrides {
    main: true;
  }
}
export const buildMuiMenu = (dynamicTheme: DynamicThemeOptions) => {
  const MuiMenu: Components["MuiMenu"] = {
    styleOverrides: {
      list: {
        minWidth: "16rem",
        ".MuiDivider-root": {
          margin: "1rem",
        },
        ".MuiListItemIcon-root": {
          color: "black",
        },
        ".MuiMenuItem-root": {
          padding: "0",
          ".sub-nav-link": {
            width: "100%",
            display: "block",
            fontSize: "16px",
            lineHeight: "22px",
            fontWeight: "500",
            color: `#000000 !important`,
            padding: "10px 20px",
            "&:hover": {
              backgroundColor: `rgba(40, 54, 118, 0.1)`,
              color: `#283676 !important`,
            },
          },
          ".MuiTypography-root": {
            fontSize: "16px",
            color: "black !important",
            fontWeight: "500",
          },
        },
      },
      paper: {
        borderRadius: 0,
      },
    },
  };
  return MuiMenu;
};
